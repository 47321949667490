<template>
  <div class="talent-search">
    <container>
      <div class="search-panel">
        <div class="input-wrap">
          <el-input />
          <el-button style="margin-left: 15px">搜索</el-button>
        </div>
        <div class="filter">
          <div class="filter-row">
            <div class="row-label">历史记录</div>
            <div class="row-content">
              <div class="item">web前端·深圳</div>
            </div>
            <span class="del-btn">
              删除
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </div>

        <div class="filter">
          <span class="del-btn">
            清空
            <i class="el-icon-delete"></i>
          </span>

          <div class="filter-row">
            <div class="row-label">热门词</div>
            <div class="row-content">
              <div class="hot">单片机开发</div>
              <div class="hot">电路设计</div>
              <div class="hot">新媒体运营</div>
              <div class="hot">Web端</div>
              <div class="hot">Windows</div>
              <div class="hot">FPGA开发</div>
            </div>
          </div>

          <div class="filter-row">
            <div class="row-label">学历</div>
            <div class="row-content">
              <div class="item">不限</div>
              <div class="item">本科及以上</div>
              <div class="item">硕士及以上</div>
              <div class="item">博士</div>
              <div class="item">自定义</div>
              <div class="item">
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="item">到</div>
              <div class="item">
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="item">|</div>
              <div class="item">
                <el-checkbox v-model="checked">统招本科</el-checkbox>
              </div>

              <div class="item">
                <el-checkbox v-model="checked">双一流</el-checkbox>
              </div>

              <div class="item">
                <el-checkbox v-model="checked">985</el-checkbox>
              </div>

              <div class="item">
                <el-checkbox v-model="checked">211</el-checkbox>
              </div>
              <div class="item">
                <el-checkbox v-model="checked">留学生</el-checkbox>
              </div>
            </div>
          </div>

          <div class="filter-row">
            <div class="row-label">经验</div>
            <div class="row-content">
              <div class="item">不限</div>
              <div class="item">在校/应届</div>
              <div class="item">1-3年</div>
              <div class="item">3-5年</div>
              <div class="item">5-10年</div>
              <div class="item">自定义</div>

              <div class="item">
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="item">至</div>
              <div class="item">
                <el-select
                  size="small"
                  style="width: 110px"
                  v-model="value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="filter-row">
            <div class="row-label">求职状态(多选)</div>
            <div class="row-content">
              <div class="hot">单片机开发</div>
              <div class="hot">电路设计</div>
              <div class="hot">新媒体运营</div>
              <div class="hot">Web端</div>
              <div class="hot">Windows</div>
              <div class="hot">FPGA开发</div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: { Container },

  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.talent-search {
  padding: 20px 0;
  .search-panel {
    padding: 30px;
    background: #fff;

    .input-wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 20px;

      ::v-deep .el-input {
        width: 60%;
      }
    }

    .filter {
      position: relative;
      font-size: 14px;

      & + .filter {
        border-top: 1px solid #ededed;
      }

      .del-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 10px;
        color: #999;
      }

      .filter-row {
        position: relative;
        padding: 15px 0;
        display: flex;
        align-items: center;

        .row-label {
          width: 100px;
          flex: 0 0 auto;
        }

        .row-content {
          flex: 1 1 auto;

          padding-right: 60px;

          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .item {
            color: #666;

            & + .item {
              margin-left: 15px;
            }
          }
          .hot {
            display: inline-block;
            background: #f8f8f8;
            padding: 4px 10px;
            border-radius: 4px;
            line-height: 1;
            vertical-align: middle;
            color: #333;

            & + .hot {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}
</style>